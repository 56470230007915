import styled from "styled-components";

import { Link } from "components/link";
import ContainerBase from "modules/DesignSystem/components/Container";
import { breakpoint } from "modules/DesignSystem/breakpoint";

import bulletImg from "./bullet.png";

export const Action = styled(Link)`
  align-self: center;
  border-radius: 4px;
  display: block;
  font-size: 14px;
  font-weight: 700;
  max-width: 300px;
  padding: 14px 32px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  @media (min-width: ${breakpoint.tablet}) {
    align-self: start;
  }
`;

export const Background = styled.img.withConfig({
  shouldForwardProp: (prop) => ["fetchPriority", "src", "alt"].includes(prop),
})`
  background-color: #2f1473;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  margin: 0 auto;

  @media (min-width: ${breakpoint.desktop}) {
    align-items: center;
    display: grid;
    grid-template-columns: 2fr 1fr;
    max-width: 1140px;
  }
`;

export const Content = styled(ContainerBase)`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 700;
  gap: 16px;

  > * {
    margin: 0;
  }

  > h1,
  h2 {
    font-size: 32px;
  }

  img {
    height: 22px;
    vertical-align: middle;
  }

  > ul {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    gap: 8px;
    line-height: 16px;
    list-style: none;
    order: 100; // List always at the end on mobile
    padding: 0;

    > li {
      padding-left: 20px;
      position: relative;

      &::before {
        aspect-ratio: 1/1;
        background: url("${bulletImg.src}") center no-repeat;
        background-size: contain;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        top: 0;
        width: 16px;
      }
    }
  }

  @media (min-width: ${breakpoint.tablet}) {
    font-size: 18px;

    img {
      height: 26px;
    }

    > ul {
      font-size: 14px;
      line-height: 20px;

      > li {
        padding-left: 28px;

        &::before {
          width: 20px;
        }
      }
    }
  }

  @media (min-width: ${breakpoint.desktop}) {
    > h1,
    h2 {
      font-size: 48px;
    }

    > ul {
      order: unset;
    }

    flex: 3;
  }
`;

export const Root = styled.div`
  overflow: hidden;
  padding: 12px 0 24px;
  position: relative;

  + .banner-carousel {
    margin-top: 16px;
  }

  @media (min-width: ${breakpoint.desktop}) {
    padding-bottom: 12px;
  }
`;
