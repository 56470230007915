import gtm from "react-gtm-module";

import { BannerCarouselSection, WebHeroSection } from "resources/AudiotekaApi";

export const getGTMElement = (section: BannerCarouselSection | WebHeroSection, index = 0) => ({
  creative_name: section.tracking_id || section.id,
  creative_slot: section.type,
  promotion_id: undefined,
  promotion_name: undefined,
  items: [
    {
      item_id: section.elements[index].tracking_id,
      item_name:
        section.type === "web-hero" ? section.elements[index].title : section.elements[index].accessibility_label,
      index,
      item_brand: "Audioteka",
      item_category: undefined,
      price: undefined,
      quantity: 1,
    },
  ],
});

export const trackCTAHomeEvent = (placement: string, button_text: string) => {
  gtm.dataLayer({
    dataLayer: {
      event: "cta_home",
      placement,
      button_text,
    },
  });
};
