import React from "react";
import Image from "next/image";

import { Text } from "app-components/text";

import type { OfferBoxConfig } from "../../offer-boxes.config";
import css from "../list.module.scss";
import checkMark from "./check-mark.png";

interface Props {
  profits: OfferBoxConfig["items"][number]["profits"];
}

export function Profits({ profits }: Props) {
  return (
    <ul className={css.list}>
      {profits.map((profit) => (
        <Text as="li" className={css.item} key={profit} type="bodyNormal">
          <Image loading="lazy" alt="" src={checkMark.src} height={checkMark.height} width={checkMark.width} />
          {profit}
        </Text>
      ))}
    </ul>
  );
}
