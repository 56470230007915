import React, { useMemo } from "react";
import gtm from "react-gtm-module";

import { isServer } from "utils/runtime";

import { homeContext } from "./home.context";
import { HomeContext, HomeEcommerce } from "./home.types";

interface DataMap {
  callback?: () => void;
  ecommerce: HomeEcommerce;
}

export const HomeProvider = ({ children }: { children: React.ReactNode }) => {
  const value = useMemo((): HomeContext => {
    const dataMap = new WeakMap<Element, DataMap>();

    const sendEvent: HomeContext["sendEvent"] = (type, ecommerce) => {
      gtm.dataLayer({
        dataLayer: {
          event: `${type}_promotion`,
          ecommerce,
        },
      });
    };

    const observer = isServer
      ? null
      : new IntersectionObserver(
          (entries, _observer) => {
            // If there are more items in event then merge them in one
            const ecommerceMap = new Map<string, HomeEcommerce>();

            entries.forEach((entry) => {
              if (!entry.isIntersecting) {
                return;
              }

              const { callback = () => undefined, ecommerce = {} as any } = dataMap.get(entry.target) || {};

              if (ecommerceMap.has(ecommerce.creative_name)) {
                ecommerceMap.get(ecommerce.creative_name).items.push(...ecommerce.items);
              } else {
                ecommerceMap.set(ecommerce.creative_name, ecommerce);
              }

              _observer.unobserve(entry.target);

              if (callback) {
                callback();
              }
            });

            Array.from(ecommerceMap.values()).forEach((_ecommerce) => sendEvent("view", _ecommerce));
          },
          { threshold: 0.5 }
        );

    return {
      observe(element, ecommerce, callback) {
        dataMap.set(element, { ecommerce, callback });
        observer.observe(element);
      },
      sendEvent,
      unobserve(element) {
        dataMap.delete(element);
      },
    };
  }, []);

  return <homeContext.Provider value={value}>{children}</homeContext.Provider>;
};
