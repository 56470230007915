export interface OfferBoxConfig {
  title: string;
  icons: Array<"visa" | "mastercard" | "blik" | "payu">;
  badge?: string;
  items: Array<{
    basic: boolean;
    paymentType: "monthly" | "one-time-6" | "one-time-12";
    name: string;
    description: string;
    promoText?: string;
    price: {
      base: string;
      promo?: string;
      duration: string;
      info?: string;
    };
    limits: {
      positive: boolean;
      list: string[];
    };
    profits: string[];
    action: {
      href: string;
      label: string;
    };
    asterisk?: string;
  }>;
}

export const offerBoxesConfig: OfferBoxConfig[] = [
  {
    title: "Miesięcznie",
    icons: ["visa", "mastercard"],
    items: [
      {
        basic: false,
        paymentType: "monthly",
        name: "Plan na Start",
        description: "Dla tych, którzy chcą zacząć słuchać",
        promoText: "Taniej przez 3 miesiące",
        price: {
          base: "17,90 zł",
          promo: null,
          duration: "miesiąc",
          info: "przez 3 miesiące, później 29,90 zł / miesiąc",
        },
        limits: {
          positive: true,
          list: ["Brak limitu godzin", "Tylko dla nowych użytkowników Audioteka Klub"],
        },
        profits: [
          "Blisko 32 tysiące tytułów w ramach subskrypcji",
          "Specjalne, klubowe ceny na pozostałe audiobooki",
          "Dostęp do aplikacji Audioteka Kids dla najmłodszych",
          "Dzielenie się audiobookami z półki z najbliższymi",
          "Wcześniejszy dostęp do gorących premier",
        ],
        action: {
          href: `${process.env.API_URL}/pl/v2/wa-subscribe/card/a45de9f9-0fd9-44f6-8eb0-4aa5146d1f3e/club_regular_promo#register`,
          label: "Wybierz",
        },
        asterisk:
          "Plan nie zawiera 14-dniowego okresu próbnego. \nZ Planu mogą skorzystać osoby, które nie korzystały z Usługi Audioteka Klub, niezależnie od planu, odpłatnie lub nieodpłatnie, zarówno w modelu subskrypcyjnym, jak i płatności jednorazowej.",
      },
      {
        basic: true,
        paymentType: "monthly",
        name: "Plan Minimalny",
        description: "Dla tych, którzy słuchają mniej",
        promoText: null,
        price: {
          base: "17,90 zł",
          promo: null,
          duration: "miesiąc",
          info: null,
        },
        limits: {
          positive: false,
          list: ["Limit 10 godzin miesięcznie"],
        },
        profits: [
          "Blisko 32 tysiące tytułów w ramach subskrypcji",
          "Specjalne, klubowe ceny na pozostałe audiobooki",
          "Dostęp do aplikacji Audioteka Kids dla najmłodszych",
          "Dzielenie się audiobookami z półki z najbliższymi",
          "Wcześniejszy dostęp do gorących premier",
        ],
        action: {
          href: `${process.env.API_URL}/pl/v2/wa-subscribe/card/7a712dae-502f-49c1-b43c-6975c3aa0887#register`,
          label: "Wybierz",
        },
        asterisk: "Plan nie zawiera 14-dniowego okresu próbnego.",
      },
      {
        basic: false,
        paymentType: "monthly",
        name: "Plan Optymalny",
        description: "Dla tych, którzy chcą słuchać bez limitu",
        promoText: null,
        price: {
          base: "29,90 zł",
          promo: null,
          duration: "miesiąc",
          info: null,
        },
        limits: {
          positive: true,
          list: ["Brak limitu godzin", "14-dniowy okres próbny dla nowych użytkowników Audioteka Klub"],
        },
        profits: [
          "Blisko 32 tysiące tytułów w ramach subskrypcji",
          "Specjalne, klubowe ceny na pozostałe audiobooki",
          "Dostęp do aplikacji Audioteka Kids dla najmłodszych",
          "Dzielenie się audiobookami z półki z najbliższymi",
          "Wcześniejszy dostęp do gorących premier",
        ],
        action: {
          href: `${process.env.API_URL}/pl/v2/wa-subscribe/card/a0adc542-894d-4dff-8a52-fab84b5efce2#register`,
          label: "Wypróbuj 14 dni za darmo",
        },
        asterisk: null,
      },
    ],
  },
  {
    title: "6 miesięcy",
    icons: ["blik", "payu"],
    items: [
      {
        basic: false,
        paymentType: "one-time-6",
        name: "Plan Optymalny na 6 miesięcy",
        description: "Pół roku słuchania bez limitu",
        promoText: null,
        price: {
          base: "179,40 zł",
          promo: null,
          duration: "6 miesięcy",
          info: null,
        },
        limits: {
          positive: true,
          list: ["Brak limitu godzin"],
        },
        profits: [
          "Blisko 32 tysiące tytułów w ramach subskrypcji",
          "Specjalne, klubowe ceny na pozostałe audiobooki",
          "Dostęp do aplikacji Audioteka Kids dla najmłodszych",
          "Dzielenie się audiobookami z półki z najbliższymi",
          "Wcześniejszy dostęp do gorących premier",
        ],
        action: {
          href: `${process.env.API_URL}/pl/v2/wa-subscribe/1d95ce37-b2d7-42ca-b68b-d94ed564bab6#register`,
          label: "Wybierz",
        },
        asterisk: null,
      },
    ],
  },
  {
    title: "12 miesięcy",
    icons: ["blik", "payu"],
    items: [
      {
        basic: false,
        paymentType: "one-time-12",
        name: "Plan Optymalny na 12 miesięcy",
        description: "12 miesięcy słuchania w cenie 10*",
        promoText: "Opłacalny wybór",
        price: {
          base: null,
          promo: "299,00 zł",
          duration: "12 miesięcy",
          info: null,
        },
        limits: {
          positive: true,
          list: ["Brak limitu godzin"],
        },
        profits: [
          "Blisko 32 tysiące tytułów w ramach subskrypcji",
          "Specjalne, klubowe ceny na pozostałe audiobooki",
          "Dostęp do aplikacji Audioteka Kids dla najmłodszych",
          "Dzielenie się audiobookami z półki z najbliższymi",
          "Wcześniejszy dostęp do gorących premier",
        ],
        action: {
          href: `${process.env.API_URL}/pl/v2/wa-subscribe/adac1d7c-5017-4de0-bff9-b344978d74d9#register`,
          label: "Wybierz",
        },
        asterisk: "*Cena odnosi się do ceny subskrypcji odnawialnej 29,90 zł / mies. w Planie Optymalnym",
      },
    ],
  },
];
