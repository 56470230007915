import styled from "styled-components";

import Button from "modules/DesignSystem/components/Button";
import ContainerBase from "modules/DesignSystem/components/Container";
import { breakpoint } from "modules/DesignSystem/breakpoint";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px 24px;
`;

export const MoreBtn = styled(Button)`
  display: block;
  margin: 16px auto 0;
  width: fit-content;
`;

export const HideRevealBtn = styled(Button)`
  display: block;
  margin: 16px auto 0;

  @media (min-width: ${breakpoint.tablet}) {
    display: none;
  }
`;

export const Wrapper = styled(ContainerBase)`
  margin: var(--screen-sections-gap) auto;
`;
