import memoize from "lodash/memoize";

export interface ColorRGB {
  red: number;
  green: number;
  blue: number;
}

export const extractRGB = memoize((hex: string): ColorRGB => {
  let red = 0;
  let green = 0;
  let blue = 0;

  if (/^#([0-9a-f]){3}$/i.test(hex)) {
    red = parseInt(hex.slice(1, 2).repeat(2), 16);
    green = parseInt(hex.slice(2, 3).repeat(2), 16);
    blue = parseInt(hex.slice(3, 4).repeat(2), 16);
  } else if (/^#([0-9a-f]){6}$/i.test(hex)) {
    red = parseInt(hex.slice(1, 3), 16);
    green = parseInt(hex.slice(3, 5), 16);
    blue = parseInt(hex.slice(5, 7), 16);
  }

  return { red, green, blue };
});

export const getContrastColor = memoize((hex: string): string => {
  const { red, green, blue } = extractRGB(hex);
  const yiq = (red * 299 + green * 587 + blue * 114) / 1000;
  return yiq >= 156 ? "#000" : "#fff";
});
