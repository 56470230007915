/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from "react";
import gtm from "react-gtm-module";
import { HalController } from "api-web-client";

import ApplicationStateContext from "modules/App/store/ApplicationStateContext";
import { setHomeScreenState } from "modules/App/store/homeScreen/actions";
import { useUser } from "modules/user";
import { useTeaserTracking } from "modules/teaser-tracking";
import { getUserHomeScreen, HomeScreen, ProductGridSection } from "resources/AudiotekaApi";

import { HomeProvider } from "./home.provider";
import {
  HomeBannerCarousel,
  ItemSets,
  Placeholder,
  ProductGrid,
  ProductGridPromo,
  WebHero,
  WebHeroCollection,
} from "./components";
import css from "./home.module.scss";

const pushDataLayer = (homeScreen: HomeScreen, me?: HalController) => {
  const recommendedSection =
    (homeScreen._embedded["app:screen-section"].find(
      (section) => section.type === "product-grid" && /-recommended-/.test(section.id)
    ) as ProductGridSection) || undefined; // i.e. audioteka-recommended-pl

  const recommended = recommendedSection
    ? recommendedSection._embedded["app:product"].map((product) => product.name)
    : [];

  const userData = me
    ? {
        user_id: me.data.tracking_id,
        created_at: me.data.created_at,
        is_anonymous: me.data.is_anonymous,
      }
    : {
        is_anonymous: true,
      };

  gtm.dataLayer({
    dataLayer: {
      event: "home",
      recommended,
      ...userData,
    },
  });
};

export const HomePage = ({ initialHomeScreen }: { initialHomeScreen: HomeScreen }) => {
  const {
    dispatch,
    state: { homeScreen },
  } = useContext(ApplicationStateContext);
  const user = useUser();

  const teaserTracking = useTeaserTracking();

  const homeState = homeScreen || initialHomeScreen;

  useEffect(() => {
    if (user.isLoading) {
      return;
    }

    if (user.isLoggedIn) {
      getUserHomeScreen().then((userHomeScreen) => dispatch(setHomeScreenState(userHomeScreen)));
    } else {
      dispatch(setHomeScreenState(initialHomeScreen));
    }
  }, [initialHomeScreen, user.isLoading, user.id]);

  useEffect(() => {
    if (!user.isLoading) {
      teaserTracking.updateObserver();
      pushDataLayer(homeState, user.hal);
    }
  }, [homeState, user.isLoading, teaserTracking, user.id]);

  return (
    <HomeProvider>
      <div className={css.homeWrapper}>
        {homeState._embedded["app:screen-section"].map((section, sectionIdx) => {
          const lazyLoading = sectionIdx >= 5;

          switch (section.type) {
            case "banner-carousel":
              return <HomeBannerCarousel key={section.id} section={section} lazyLoading={lazyLoading} />;
            case "item-sets":
              return <ItemSets key={section.id} section={section} />;
            case "placeholder":
              return <Placeholder key={section.id} section={section} />;
            case "product-grid":
              return <ProductGrid key={section.id} lazyLoading={lazyLoading} section={section} />;
            case "product-grid-promo":
              return <ProductGridPromo key={section.id} lazyLoading={lazyLoading} section={section} />;
            case "web-hero":
              return <WebHero key={section.id} section={section} />;
            case "web-hero-collection":
              return <WebHeroCollection key={section.id} section={section} />;
            default:
              return null;
          }
        })}
      </div>
    </HomeProvider>
  );
};
