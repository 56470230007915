import React from "react";
import styled from "styled-components";
import { HalController } from "api-web-client";

import TeaserList from "modules/DesignSystem/components/TeaserList";
import { ProductGridSection } from "resources/AudiotekaApi";

const Wrapper = styled.div`
  margin: var(--screen-sections-gap) auto;
`;

export const ProductGrid = ({ lazyLoading, section }: { lazyLoading: boolean; section: ProductGridSection }) => {
  const { id, title, deeplink, label, tracking_id: trackingId, web_deeplink } = section;
  const more = (web_deeplink || deeplink) && label ? { link: web_deeplink || deeplink, text: label } : undefined;
  const elements = section._embedded["app:product"].slice(0, 20).map((product) => HalController.fromObject(product));

  return (
    <Wrapper>
      <TeaserList
        title={title}
        elements={elements}
        trackingId={trackingId || id}
        more={more}
        noPrice
        lazyLoading={lazyLoading}
      />
    </Wrapper>
  );
};

export default ProductGrid;
