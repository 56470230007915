import React from "react";
import classNames from "classnames";

import { Text } from "app-components/text";

import type { OfferBoxConfig } from "../../offer-boxes.config";
import css from "./price-box.module.scss";

interface Props {
  price: OfferBoxConfig["items"][0]["price"];
}

export function PriceBox({ price }: Props) {
  const isPromo = !!price.promo;

  return (
    <div className={css.box}>
      <Text as="p" className={classNames(css.priceRow, { [css.promo]: isPromo })} type="heading4">
        {isPromo && price.base && (
          <Text as="span" className={css.oldPrice} type="bodyNormal">
            {price.base}
          </Text>
        )}
        {price.promo ?? price.base}
        {price.duration && (
          <Text as="span" className={css.duration} type="heading6">
            / {price.duration}
          </Text>
        )}
      </Text>
      {price.info && (
        <Text className={css.info} type="subtitleNormal">
          {price.info}
        </Text>
      )}
    </div>
  );
}
