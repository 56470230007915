import React, { CSSProperties } from "react";
import { HalController } from "api-web-client";

import { ProductGridPromoSection } from "resources/AudiotekaApi";
import Markdown from "components/markdown";
import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { ContainerBase } from "modules/DesignSystem/components/Container";
import TeaserList from "modules/DesignSystem/components/TeaserList";
import Button from "modules/DesignSystem/components/Button";
import { breakpoint } from "modules/DesignSystem/breakpoint";

import { trackCTAHomeEvent } from "../../home.utils";
import { PromoTop } from "./promo-top/promo-top";
import css from "./product-grid-promo.module.scss";

export const ProductGridPromo = ({
  lazyLoading,
  section,
}: {
  lazyLoading: boolean;
  section: ProductGridPromoSection;
}) => {
  const [element] = section.elements;

  const products = section._embedded["app:product"].slice(0, 5).map((product) => HalController.fromObject(product));

  return (
    <div className={css.productGridPromo} style={{ color: `#${element.description_color}` } as CSSProperties}>
      <picture className={css.backgroundPicture}>
        <source media={`(min-width: ${breakpoint.tablet})`} srcSet={`${element.background_image_url}?auto=format`} />
        <img loading="lazy" src={`${element.mobile_background_image_url}?auto=format`} alt="" />
      </picture>
      <ContainerBase className={css.content}>
        <PromoTop
          color={element.title_color}
          sideButton={
            element.side_button_deeplink
              ? {
                  color: element.side_button_label_color ? `#${element.side_button_label_color}` : null,
                  deeplink: element.side_button_deeplink,
                  label: element.side_button_label,
                }
              : null
          }
          title={element.title}
        />
        <TeaserList
          className={css.teaserList}
          colorless
          compact
          elements={products}
          lazyLoading={lazyLoading}
          trackingId={element.tracking_id || section.id}
          noPrice
        />
        {element.description ? (
          <div className={css.description}>
            <Markdown text={element.description} />
          </div>
        ) : null}
        {element.button_deeplink ? (
          <Button
            className={css.action}
            {...deeplinkTranslator(element.button_deeplink)}
            style={{ backgroundColor: `#${element.button_color}`, color: `#${element.button_label_color}` }}
            onClick={() => trackCTAHomeEvent(element.title, element.button_label)}
          >
            {element.button_label}
          </Button>
        ) : null}
      </ContainerBase>
    </div>
  );
};
