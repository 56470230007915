import React, { ComponentProps } from "react";
import classNames from "classnames";

import { Link } from "app-components/link";

import type { ButtonColor } from "./button.types";
import css from "./button.module.scss";

type Props = ComponentProps<typeof Link> & {
  color?: ButtonColor;
  outlined?: boolean;
};

export function ButtonLink({ children, className, color = "blue", outlined, ...props }: Props) {
  const classes = classNames(css.button, className, css[color], { [css.outlined]: outlined });

  return (
    <Link className={classes} {...props}>
      {children}
    </Link>
  );
}
