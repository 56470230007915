import React from "react";
import styled from "styled-components";

import { WebHeroCollectionItem } from "resources/AudiotekaApi";
import { Link as LinkBase } from "components/link";
import { breakpoint } from "modules/DesignSystem/breakpoint";

const Link = styled(LinkBase)`
  aspect-ratio: 1/1;
  border-radius: 4px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25);
  display: block;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease-in-out;
  width: var(--item-size, 112px);
  z-index: 1;

  > img {
    height: 100%;
    width: 100%;
  }

  @media (min-width: ${breakpoint.desktop}) {
    &:hover {
      box-shadow: 0 0 32px #d518ff;
      transform: scale(1.1);
      z-index: 2;
    }
  }
`;

export const CollectionItem = ({ item }: { item: WebHeroCollectionItem }) => {
  return (
    <Link route={item.type} params={{ id: item.slug || item.id }}>
      <img
        // eslint-disable-next-line react/no-unknown-property
        fetchPriority="high"
        src={`${item.image_url}?w=150&auto=format`}
        alt={item.name}
      />
    </Link>
  );
};
