import styled from "styled-components";
import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";

export const IconBox = styled.div`
  align-items: center;
  background: #380089;
  border-radius: 16px;
  color: #fff;
  display: flex;
  height: 56px;
  justify-content: center;
  width: 56px;
`;

export const Item = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  scroll-snap-align: center;
  text-align: center;
  width: 100%;
`;

export const ItemDescription = styled.p`
  color: #16082999;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
`;

export const ItemTitle = styled.p`
  flex: 1;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 16px 0 8px;

  .pink {
    color: #d518ff;
    text-decoration: none;
  }
`;

export const List = styled.div`
  align-items: stretch;
  column-gap: 24px;
  display: grid;
  grid-template-columns: repeat(4, 280px);
  margin: 0 auto;
  max-width: 100%;
  overflow-x: auto;
  padding: 12px;
  scroll-snap-type: x mandatory;
  width: fit-content;

  &::-webkit-scrollbar {
    height: 0;
  }
`;

export const Root = styled.div`
  margin: 64px auto;
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;
  margin: 24px 0;
  text-align: center;

  @media screen and ${MEDIA_QUERY.lg} {
    font-size: 26px;
    margin: 32px 0;
  }
`;
