import React, { useEffect, useMemo, useRef } from "react";

import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { WebHeroSection } from "resources/AudiotekaApi";
import { Hero } from "components/hero";

import { useHome } from "../../home.hooks";
import { getGTMElement } from "../../home.utils";

export const WebHero = ({ section }: { section: WebHeroSection }) => {
  const {
    elements: [element],
  } = section;

  const heroRef = useRef<HTMLDivElement>();

  const home = useHome();

  const gtmData = useMemo(() => getGTMElement(section), [section]);

  useEffect(() => {
    const { current: root } = heroRef;

    if (!root) {
      return () => undefined;
    }

    home.observe(root, gtmData);

    return () => {
      home.unobserve(root);
    };
  }, [gtmData, heroRef.current]);

  return (
    <Hero backgroundUrl={element.background_image_url} bannerUrl={element.banner_image_url} ref={heroRef}>
      <Hero.Text type="heading1" as="p" color={element.title_color}>
        {element.title}
      </Hero.Text>
      <Hero.Text type="bodyExtraBig" as="p" color={element.description_color}>
        {element.description}
      </Hero.Text>
      <Hero.Button
        bgColor={element.button_color}
        textColor={element.button_label_color}
        onClick={() => home.sendEvent("select", gtmData)}
        {...deeplinkTranslator(element.button_deeplink)}
      >
        {element.button_label}
      </Hero.Button>
    </Hero>
  );
};
