import React, { ComponentProps, FC, memo } from "react";
import { Converter, ShowdownOptions } from "showdown";

import { HtmlToReact } from "../html-to-react";

interface Props extends Omit<ComponentProps<typeof HtmlToReact>, "html"> {
  options?: ShowdownOptions;
  text: string;
}

export const Markdown: FC<Props> = memo(({ options, text, ...props }) => {
  const converter = new Converter(options);

  return <HtmlToReact {...props} html={converter.makeHtml(text)} />;
});

export default Markdown;
