/* eslint-disable react/no-array-index-key */
import React from "react";
import classNames from "classnames";

import { ButtonPrimitive } from "app-components/button/button-primitive";

import { useDotButtons } from "./use-dot-buttons";
import css from "./dot-buttons.module.scss";

interface Props {
  emblaApi: Parameters<typeof useDotButtons>[0];
}

export function DotButtons({ emblaApi }: Props) {
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButtons(emblaApi);

  if (scrollSnaps.length === 0) {
    // empty container used to avoid layout shift
    return <div className={css.dotButtons} />;
  }

  return (
    <div className={css.dotButtons}>
      {scrollSnaps.map((snap, index) => (
        <ButtonPrimitive
          className={classNames(css.dotButton, { [css.dotButton__active]: index === selectedIndex })}
          key={index}
          onClick={() => onDotButtonClick(index)}
        />
      ))}
    </div>
  );
}
