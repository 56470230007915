/* eslint-disable react/no-array-index-key */

import React from "react";

import { offerBoxesConfig } from "./offer-boxes.config";
import { TabBtn } from "./tab-btn/tab-btn";
import css from "./tab-btn-group.module.scss";

interface Props {
  activeTabIdx: number;
  onClick(tabIdx: number): void;
}

export function TabBtnGroup({ activeTabIdx, onClick }: Props) {
  return (
    <div className={css.tabBtnGroup}>
      {offerBoxesConfig.map((tab, tabIdx) => (
        <TabBtn
          key={`offer-tab-${tabIdx}`}
          active={activeTabIdx === tabIdx}
          badge={tab.badge}
          icons={tab.icons}
          onClick={() => onClick(tabIdx)}
        >
          {tab.title}
        </TabBtn>
      ))}
    </div>
  );
}
