import React from "react";

import Markdown from "components/markdown";
import { WebHeroCollectionSection } from "resources/AudiotekaApi";
import { deeplinkTranslator } from "modules/App/deeplinkTranslator";

import { trackCTAHomeEvent } from "../../home.utils";
import { Collection } from "./Collection";
import { Action, Background, Container, Content, Root } from "./WebHeroCollection.styled";

export const WebHeroCollection = ({ section }: { section: WebHeroCollectionSection }) => {
  const [hero] = section.elements;

  return (
    <Root>
      <Background fetchPriority="high" src={`${hero.background_image_url}?auto=format`} alt="" />
      <Container>
        <Content style={{ color: `#${hero.description_color}` }}>
          <Markdown text={hero.description} />
          {hero.button_label && hero.button_deeplink ? (
            <Action
              style={{ backgroundColor: `#${hero.button_color}`, color: `#${hero.button_label_color}` }}
              onClick={() => trackCTAHomeEvent("web_hero", hero.button_label)}
              {...deeplinkTranslator(hero.button_deeplink, hero.slug)}
            >
              {hero.button_label}
            </Action>
          ) : null}
        </Content>
        <Collection items={hero.collection} />
      </Container>
    </Root>
  );
};
