import React from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

import { BannerCarouselSection } from "resources/AudiotekaApi";

import { Slide } from "./slide/slide";
import { DotButtons } from "./dot-buttons/dot-buttons";
import { ArrowButtons } from "./arrow-buttons/arrow-buttons";
import css from "./banner-carousel.module.scss";

interface Props {
  lazyLoading?: boolean;
  section: BannerCarouselSection;
  onClick?: (element: BannerCarouselSection["elements"][number]) => void;
}

export function BannerCarousel({ lazyLoading, section, onClick }: Props) {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, align: () => 16 }, [
    Autoplay({ stopOnInteraction: false, stopOnMouseEnter: true }),
  ]);

  const { elements } = section;

  return (
    <div className={css.bannerCarousel}>
      <div className={css.viewport} ref={emblaRef}>
        <div className={css.slides}>
          {elements.map((element) => (
            <Slide
              key={element.tracking_id}
              lazyLoading={lazyLoading}
              element={element}
              onClick={() => onClick?.(element)}
            />
          ))}
        </div>
        <ArrowButtons emblaApi={emblaApi} />
      </div>
      <DotButtons emblaApi={emblaApi} />
    </div>
  );
}
