import React from "react";

import { Text } from "app-components/text";
import { ButtonLink } from "app-components/button/button-link";

import css from "./offer-footer.module.scss";

export function OfferFooter() {
  return (
    <div className={css.offerFooter}>
      <Text className={css.text} type="subtitleBig">
        Chcesz uzyskać więcej informacji o&nbsp;Audioteka&nbsp;Klub?
      </Text>
      <ButtonLink color="opaque" href="https://pages.audioteka.com/pl/audioteka-klub/">
        Dowiedz się więcej
      </ButtonLink>
    </div>
  );
}
