import React from "react";

import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { Link } from "components/link";

import css from "./promo-top.module.scss";

interface PromoSideButton {
  color: string | null;
  deeplink: string;
  label: string;
}

export const PromoTop = ({
  color,
  sideButton,
  title,
}: {
  color: string;
  sideButton: PromoSideButton | null;
  title: string;
}) => {
  return (
    <div className={css.promoTop}>
      <h2 className={css.title} style={{ color: color ? `#${color}` : null }}>
        {title}
      </h2>
      {sideButton && (
        <Link
          className={css.sideButton}
          {...deeplinkTranslator(sideButton.deeplink)}
          style={{ color: sideButton.color || "inherit" }}
        >
          {sideButton.label} &gt;
        </Link>
      )}
    </div>
  );
};
