import React from "react";
import classNames from "classnames";

import { ButtonLink } from "app-components/button/button-link";
import { Text } from "app-components/text";

import type { OfferBoxConfig } from "../offer-boxes.config";
import { Limits } from "./limits/limits";
import { PriceBox } from "./price-box/price-box";
import { Profits } from "./profits/profits";
import css from "./offer-box.module.scss";

interface Props {
  offer: OfferBoxConfig["items"][0];
}

export function OfferBox({ offer }: Props) {
  const isPromo = Boolean(offer.promoText);

  return (
    <div className={css.offerBox}>
      <div className={classNames(css.promoWrapper, { [css.active]: isPromo })}>
        <Text className={css.promoText} type="subtitleNormal">
          {offer.promoText}
        </Text>
        <div className={classNames(css.box, { [css.active]: offer.promoText })}>
          <Text as="p" className={css.title} type="heading5">
            {offer.name}
          </Text>
          <Text className={css.description} type="bodyNormal">
            {offer.description}
          </Text>
          <PriceBox price={offer.price} />
          <Limits limits={offer.limits} />
          <hr className={css.separator} />
          <Profits profits={offer.profits} />
          <ButtonLink
            className={css.action}
            color={isPromo ? "club" : "white"}
            href={offer.action.href}
            outlined={!isPromo}
          >
            {offer.action.label}
          </ButtonLink>
        </div>
      </div>
      <Text className={css.asterisk} type="bodyNormal">
        {offer.asterisk}
      </Text>
    </div>
  );
}
