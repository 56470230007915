import React from "react";
import styled from "styled-components";

import { WebHeroCollectionItem } from "resources/AudiotekaApi";
import { breakpoint } from "modules/DesignSystem/breakpoint";

import { CollectionItem } from "./CollectionItem";

const Root = styled.div`
  --item-size: 75px;
  align-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;

  @media (min-width: ${breakpoint.tablet}) {
    --item-size: 125px;
    gap: 16px;
  }

  @media (min-width: ${breakpoint.desktop}) {
    --item-size: 112px;
    gap: 8px 16px;
    height: 472px;
    margin: 0;
    padding: calc(var(--item-size) * 0.5) 0;
    transform: none;
    width: 100%;
  }
`;

const Section = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;
  gap: 4px;
  overflow: hidden;
  width: 100%;

  &:first-child {
    justify-content: start;
  }

  &:last-child {
    justify-content: end;
  }

  @media (min-width: ${breakpoint.tablet}) {
    gap: 16px;
  }

  @media (min-width: ${breakpoint.desktop}) {
    display: contents;

    &:first-child {
      > *:nth-child(4) {
        margin-top: calc(var(--item-size) * -0.5);
      }
    }

    &:last-child {
      > *:nth-child(2) {
        margin-bottom: calc(var(--item-size) * -0.5);
      }
    }
  }
`;

export const Collection = ({ items }: { items: WebHeroCollectionItem[] }) => {
  const itemsTop = items.slice(0, 5);
  const itemsBottom = items.slice(5, 10);

  return (
    <Root>
      <Section>
        {itemsTop.map((item) => (
          <CollectionItem key={item.id} item={item} />
        ))}
      </Section>
      <Section>
        {itemsBottom.map((item) => (
          <CollectionItem key={item.id} item={item} />
        ))}
      </Section>
    </Root>
  );
};
