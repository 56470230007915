import React from "react";
import { useTranslations } from "next-intl";

import Link from "components/link";
import { Accordion } from "app-components/accordion";

import { Description, List, Root, Title } from "./Faq.styled";

const pairNumbers = ["1", "2", "3", "4", "5", "6"] as const;

const Faq = () => {
  const t = useTranslations("home.faq");

  return (
    <Root>
      <Title>{t("title")}</Title>
      <Description>
        {t("description.text")}
        <Link route="helpCenter">{t("description.link_label")}</Link>
      </Description>
      <List>
        {pairNumbers.map((pairNumber) => (
          <Accordion key={pairNumber} id={`question-${pairNumber}`} title={t(`question${pairNumber}`)}>
            {t(`answer${pairNumber}`)}
          </Accordion>
        ))}
      </List>
    </Root>
  );
};

export default Faq;
