import React from "react";

import { ButtonPrimitive } from "app-components/button/button-primitive";
import { NewIcon } from "components/NewIcon";

import { useArrowButtons } from "./use-arrow-buttons";
import css from "./arrow-buttons.module.scss";

interface Props {
  emblaApi: Parameters<typeof useArrowButtons>[0];
}

export function ArrowButtons({ emblaApi }: Props) {
  const { onPrevButtonClick, onNextButtonClick } = useArrowButtons(emblaApi);

  return (
    <div className={css.arrowButtons}>
      <ButtonPrimitive className={css.arrowButton} onClick={onPrevButtonClick}>
        <NewIcon icon="chevron-left" width="30" height="30" />
      </ButtonPrimitive>
      <ButtonPrimitive className={css.arrowButton} onClick={onNextButtonClick}>
        <NewIcon icon="chevron-right" width="30" height="30" />
      </ButtonPrimitive>
    </div>
  );
}
