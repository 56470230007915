import React from "react";
import Image from "next/image";

import { Text } from "app-components/text";

import type { OfferBoxConfig } from "../../offer-boxes.config";
import css from "../list.module.scss";

import checkMark from "./check-mark.png";
import exclamationMark from "./exclamation-mark.png";

interface Props {
  limits: OfferBoxConfig["items"][number]["limits"];
}

export function Limits({ limits }: Props) {
  return (
    <ul className={css.list}>
      {limits.list.map((limit) => {
        const icon = limits.positive ? checkMark : exclamationMark;

        return (
          <Text as="li" className={css.item} key={limit} type="subtitleNormal">
            <Image loading="lazy" alt="" src={icon.src} height={icon.height} width={icon.width} />
            {limit}
          </Text>
        );
      })}
    </ul>
  );
}
